import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'
import { util } from '@/utils/utils.js'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const ProposalService = {
  formatNewForm (data) {
    let formatedData = {}
    formatedData.idorigem = data.origem.id
    formatedData.idCliente = data.cliente.id
    formatedData.iddestino = data.destino.id
    formatedData.idSysCotacaoLog = data.idSysCotacaoLog
    formatedData.idincoterm = data.incoterm
    formatedData.dataEmbarque = data.data_embarque
    formatedData.special = data.special
    formatedData.brandScac = data.brandScac
    formatedData.idMercadoria = data.mercadoria.id
    formatedData.transitTime = data.transitTime
    formatedData.tipoOperacao = data.tipoOperacao
    formatedData.tipoCarga = data.tipoCarga
    formatedData.modalidadeProcesso = data.modalidadeProcesso
    formatedData.dataPrevisaoEmbarque = data.previsao_embarque
    formatedData.idtarifarioMaritimo = data.id_tarifario_maritimo
    formatedData.servicoComTransbordo = data.servico_com_transbordo
    formatedData.dataInicioVigencia = data.data_inicio_vigencia
    formatedData.dataValidade = data.data_validade

    if (data.cupom && data.cupom.id !== null) {
      formatedData.cupom = {
        idcupom: data.cupom.id
      }
    }

    // FCL
    if (formatedData.tipoCarga === 3) {
      formatedData.equipamentos = data.equipamentos.map(x => ({
        freeTimeOrigem: x.free_time_destino,
        freeTimeDestino: x.free_time_origem,
        idequipamentoMaritimo: x.id,
        quantidade: x.quantidade
      }))
    } else {
      formatedData.equipamentos = []
    }

    formatedData.taxas = data.taxas.map(x => ({
      tipo: x.tipo,
      quantidade: x.quantidade,
      descricao: x.descricao,
      valorUnitario: x.valor_unitario,
      valorMinimo: x.valor_pagamento_minimo,
      moeda: x.moeda,
      unidade: x.unidade
    }))

    formatedData.carga = {
      seguroFreteMercadoria: data.adicionais.seguro.freteMercadoria
    }

    if (formatedData.tipoCarga === 4) {
      formatedData.carga.cbm = data.cbm
      formatedData.carga.pesoBruto = data.peso_bruto
    }

    return formatedData
  },
  checkCupom (proposalId, cupomId) {
    let queryString = httpBuildQuery({ proposalId: proposalId, cupomId: cupomId })
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/cupom/check?${queryString}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  addCupom (proposalId, cupom) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/proposal/${proposalId}/cupom`, cupom).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  removeCupom (proposalId) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/proposal/${proposalId}/cupom`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  formatPersonForm (data) {
    let formatedData = {}
    formatedData.cnpjVatNumber = data.cnpj_vatnumber
    formatedData.nomeFantasia = null
    formatedData.nome = data.nome
    formatedData.cep = data.cep
    formatedData.logradouro = data.logradouro
    formatedData.numero = null
    formatedData.complemento = null
    formatedData.bairro = null
    formatedData.fone = data.telefone
    formatedData.email = data.email
    formatedData.idpais = data.pais.id
    formatedData.idunidadeFederativa = (data.estado) ? data.estado.id : null
    formatedData.idmunicipio = (data.cidade) ? data.cidade.id : null
    formatedData.ufExterior = (data.ufExterior) ? data.ufExterior : null
    formatedData.municipioExterior = (data.municipioExterior) ? data.municipioExterior : null
    formatedData.contatos = data.contatos
    formatedData.favorito = data.favorito

    return formatedData
  },
  formatCargoForm (data) {
    let formatedData = {
      referenciaCliente: data.referencia_cliente,
      comercialInvoice: data.comercial_invoice,
      carga: {
        cargaNaoPerigosa: data.carga.segura_naopessoal,
        idncm: (!data.comercial_invoice) ? data.carga.ncm.id : null,
        descricao: (!data.comercial_invoice) ? data.carga.descricao : null,
        quantidadeVolumes: (!data.comercial_invoice) ? data.carga.quantidade : null,
        idtipoEmbalagem: (!data.comercial_invoice) ? data.carga.tipo_volume.id : null,
        pesoBruto: (!data.comercial_invoice) ? util.getNumberFromFormated(data.carga.peso_bruto) : null
      }
    }

    if (data.comercial_invoice === true) {
      formatedData.arquivos = data.arquivos
    } else {
      formatedData.arquivos = []
    }

    return formatedData
  },
  formatUpSellForm (data) {
    let formatedData = {
      carga: {
        valorMercadoria: util.getNumberFromFormated(data.valor_mercadoria),
        seguroFreteMercadoria: data.freteMercadoria,
        seguroImpostos: data.impostos,
        seguroOutrasDespesas: data.outras_despesas,
        valorSeguro: data.valor_seguro,
        importanciaSegurada: null,
        percentualSeguro: null
      }
    }

    return formatedData
  },
  delete (id) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/proposal/${id}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postNewProposal (data) {
    let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/proposal/new`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putStepCargo (id, data) {
    let formData = this.formatCargoForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/proposal/${id}/cargo`, formData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putStepPerson (id, type, data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/proposal/${id}/person/${type}`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putStepUpSell (id, data) {
    let formData = this.formatUpSellForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/proposal/${id}/upsell`, formData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putProposal (id, data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/proposal/${id}`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getProposal (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/proposal/${id}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getPersons (data) {
    let queryString = httpBuildQuery(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/solicitacao-pessoa?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postPerson (data) {
    let formData = this.formatPersonForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/solicitacao-pessoa`, formData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putPerson (data) {
    let formData = this.formatPersonForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/solicitacao-pessoa/${data.id}`, formData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getPersonContacts (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/solicitacao-pessoa/${id}/contacts`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  addContact (id, data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/proposal/${id}/contact`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getContacts (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/proposal/${id}/contacts`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  deleteContact (id, idContact) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/proposal/${id}/contact/${idContact}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postSend (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/proposal/${id}/send`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postSendNoOptions (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/proposal/send/no-option`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * Return active customers enabled to create a Proposal
   *
   * @param filter
   * @returns {Promise<unknown>}
   */
  getCustomers (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/proposal/customer?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getFile (id, fileName) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/proposal/${id}/file/${fileName}`, {
          responseType: 'blob'
        }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default ProposalService
