<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="webhookForm" ref="form" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-4">
                  <h5 class="box-title mb-3">{{ $t('Dados gerais') }}</h5>
                </div>
                <!-- Cliente -->
                <cliente-field
                  :grey-field="false"
                  name="formData.idCliente"
                  v-model="formData.idCliente"
                  rootClassName="col-12 col-lg-12 form-group mb-4"
                  v-validate="'required'"
                  :error="errors.first('formData.idCliente')"
                />

                <!-- Descrição -->
                <custom-input
                  v-model="formData.descricao"
                  name="formData.descricao"
                  :label="this.$i18n.t('form.integrations.webhooks.descricao')"
                  type="text"
                  rootClassName="col-12 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: false }"
                  :error="submitted && errors.first('formData.descricao') ? errors.first('formData.descricao') : ''">
                </custom-input>

                <!-- Webhook -->
                <custom-input
                  v-model="formData.webhook"
                  name="formData.webhook"
                  :label="this.$i18n.t('form.integrations.webhooks.webhook')"
                  type="text"
                  rootClassName="col-12 col-sm-12 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="submitted && errors.first('formData.webhook') ? errors.first('formData.webhook') : ''">
                </custom-input>

                <!-- Token -->
                <custom-input
                  v-model="formData.token"
                  name="formData.token"
                  :label="this.$i18n.t('form.integrations.webhooks.token')"
                  type="text"
                  rootClassName="col-12 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="submitted && errors.first('formData.token') ? errors.first('formData.token') : ''">
                </custom-input>

                <!-- Observação -->
                <div class="col-12 col-md-12">
                  <custom-textarea
                    v-model="formData.observacao"
                    name="formData.observacao"
                    label="Observações"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    :rows="10"
                    v-validate="{ required: false }"
                    background="#ffffff"
                    :error="errors.first('formData.observacao')">
                  </custom-textarea>
                </div>

                <div class="col-12 mb-4">
                  <h5 class="box-title mb-0">Integração</h5>
                </div>

                <!-- Integração -->
                <div class="col-12" v-for="(rota, index) in formData.integracaoRota" v-bind:key="index">
                  <div class="row">
                    <div class="pl-0 col-12 mb-2">
                      <span class="font-weight-bold" v-if="rota.tipo === 1">Processos Aéreos</span>
                      <span class="font-weight-bold" v-if="rota.tipo === 2">Processos FCL</span>
                      <span class="font-weight-bold" v-if="rota.tipo === 24">Processos LCL</span>
                    </div>

                    <custom-input
                      v-model="formData.integracaoRota[index].endpoint"
                      :name="'formData.integracaoRota[' + index + '].endpoint'"
                      label="Endpoint"
                      type="text"
                      rootClassName="pl-0 col-12 col-sm-12 form-group mb-4"
                      inputClassName="md-form-control"
                      v-validate="{ required: false }"
                      :error="submitted && errors.first('formData.integracaoRota[' + index + '].endpoint') ? errors.first('formData.integracaoRota[' + index + '].endpoint') : ''">
                    </custom-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import ClienteField from '@/components/Forms/ClienteField'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import OportunidadeService from '@/services/OportunidadeService'
import WebhookService from '@/services/WebhookService'

export default {
  name: 'WebhookForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('oportunidades.title') + ' - %s'
    }
  },
  data () {
    return {
      // Configuration to mount table of selected values
      formData: {
        idCliente: null,
        descricao: null,
        observacao: null,
        webhook: null,
        token: null,
        ativo: true,
        integracaoRota: [
          {
            tipo: 1,
            descricao: null,
            endpoint: null
          },
          {
            tipo: 2,
            descricao: null,
            endpoint: null
          },
          {
            tipo: 24,
            descricao: null,
            endpoint: null
          }
        ]
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      submitted: false
    }
  },
  components: {
    ClienteField,
    CustomTextarea,
    Loading,
    CustomInput
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data

    let promises = [
    ]

    // If is edit populate values
    let id = this.$route.params.id | this.$route.query.id

    if (id) {
      promises.push(WebhookService.get(id))
    }

    Promise.all(promises).then((values) => {
      // Edit Info
      if (id) {
        let integracao = values[0].data.data
        _this.formData = integracao
      }

      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    fileDownload (file) {
      this.isLoading = true
      let idOportunidade = this.$route.params.id
      OportunidadeService.getFile(idOportunidade, file.filename).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', file.originalFilename)
        global.document.body.appendChild(link)
        link.click()
        this.isLoading = false
      })
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.oportunidade.statusList.aguardando'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.oportunidade.statusList.trabalhando'),
          'color': '#ababab'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.oportunidade.statusList.disponivel'),
          'color': '#5ec65f'
        },
        {
          'id': 9,
          'label': this.$i18n.t('form.oportunidade.statusList.expirado'),
          'color': '#ababab'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    getOriginDestinationType (modalidadeProcesso) {
      if (modalidadeProcesso && modalidadeProcesso.id === 2) {
        // Marítimo
        return 1
      } else if (modalidadeProcesso && modalidadeProcesso.id === 1) {
        // Aéreo
        return 2
      } else if (modalidadeProcesso && modalidadeProcesso.id === 3) {
        // Aéreo
        return 3
      }
      // Padrão Maritimo
      return null
    },
    onCancel (e) {
      let _this = this

      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja cancelar.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (result) => {
        if (result) {
          _this.isLoading = true
          _this.$router.push({ name: 'WebhookIndex' })
        }
      })
    },
    displayErrors (errors) {
      // @TODO: display errors from server
      util.getFormErrors(errors)
      // let formatedErrors = util.getFormErrors(errors)
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('webhookForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)
          data.idCliente = data.idCliente.id

          let id = this.$route.params.id
          if (id) {
            WebhookService.put(id, data).then(response => {
              _this.$router.push({ name: 'WebhookIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
            }).finally(() => {
              _this.isLoading = false
            })
          } else {
            WebhookService.post(data).then(response => {
              _this.$router.push({ name: 'WebhookIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
            }).finally(() => {
              _this.isLoading = false
            })
          }
        } else {
          _this.$util.scrollToFirstError()
        }
      })
    },
    onSelectModality (val) {
      this.formData.tipoCarga = null
    }
  },
  computed: {
    showTipoCarga () {
      if (this.formData.modalidadeProcesso && this.formData.modalidadeProcesso.id === 2) {
        return true
      }

      return false
    },
    modalidadeProcessoId () {
      if (this.formData.modalidadeProcesso) {
        return this.formData.modalidadeProcesso.id
      }

      return null
    },
    tipoCargaId () {
      if (this.formData.tipoCarga) {
        return this.formData.tipoCarga.id
      }

      return null
    },
    originDestinationDisabled () {
      return !this.formData.modalidadeProcesso
    },
    unidadeMedidaDisabled () {
      // Se vazio então desabilitar
      if (!this.formData.modalidadeProcesso) {
        return true
      }

      if (this.formData.modalidadeProcesso.id === 2 && !this.formData.tipoCarga) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }
</style>
